<template>
	<el-collapse class="package-collapse" :model-value="activeNames">
		<el-collapse-item :title="`Package ${index+1}`" name="1">
			<template v-if="appProvide.xs">
				<ul class="package-list">
					<li 
						v-for="(item,index) of data"
						:key="index"
						class="package">
						<img :src="item.productImgUrl" alt="">
						<div class="right">
							<p class="trade-name">{{item.title}}</p>
							<p class="trade-sku">{{item.sku}}</p>
							<div class="bottom"><span class="quantity">x{{item.quantity}}</span><span class="unit-price">{{`${item.price.currencyCode} ${item.price.amount}`}}</span></div>
						</div>
					</li>
				</ul>
			</template>
			<template v-else>
				<el-table
					:data="data"
					border
					style="width: 100%;">
					<el-table-column
						prop="productImgUrl"
						label="Picture"
						align="center">
						<template #default="{row}">
							<img :src="row.productImgUrl" style="width: 100%;" v-if="row.productImgUrl"/>
						</template>
					</el-table-column>
					<el-table-column
						prop="title"
						label="Trade Name"
						align="center">
					</el-table-column>
					<el-table-column
						prop="sku"
						label="Trade SKU"
						align="center">
					</el-table-column>
					<el-table-column
						prop="quantity"
						label="Quantity"
						align="center">
					</el-table-column>
					<el-table-column
						label="Unit Price"
						align="center">
						<template #default="{row}">
							{{`${row.price.currencyCode} ${row.price.amount}`}}
						</template>
					</el-table-column>
				</el-table>
			</template>
		</el-collapse-item>
	</el-collapse>
</template>
<script lang="ts">
import { defineComponent, inject } from 'vue'

export default defineComponent({
  name : 'ItemPackageTable',
  props: {
    data       : Array,
    index      : Number,
    activeNames: Array,
  },
  setup() {
    const appProvide = inject('appProvide')
    return {
      appProvide,
    }
  },
})
</script>
<style lang="scss">
.package-list {
  padding: 0;
  margin: 0;
  list-style: none;

  p,
  div {
    padding: 0;
    margin: 0;
    line-height: 1;
  }

  .package {
    display: flex;
    padding: 10px;
    border: 1px solid $border-color-light;

    + .package {
      border-top: none;
    }
  }

  img {
    flex-shrink: 0;
    width: 90px;
    height: 90px;
  }

  .right {
    display: flex;
    flex: 1;
    flex-direction: column;
    justify-content: space-between;
    padding-left: 10px;
    font-size: 14px;

    .trade-name {
      color: #000;
    }

    .trade-sku {
      flex: 1;
      margin-top: 10px;
    }

    .bottom {
      display: flex;
      justify-content: space-between;
      font-weight: bold;
    }

    .quantity {
      color: #000;
    }

    .unit-price {
      color: $--color-primary;
    }
  }
}
</style>