<template>
  <el-row style="padding: 0 5px">
    <el-col
      :lg="{ span: 16, push: 4 }"
      :md="{ span: 20, push: 2 }"
      :sm="{ span: 21, push: 1 }"
      :xs="24"
    >
      <el-affix :offset="0">
        <div class="affix">
          <h3 class="title">Order Tracking</h3>
          <div class="query-wrapper">
            <p class="tip" v-if="tip.show">{{ tip.content }}</p>
            <el-row :gutter="10" style="flex: 1">
              <el-col :lg="12" :md="12" :sm="12" :xs="24" class="input-col">
                <el-input
                  v-model="input"
                  @input="onInput"
                  @keyup.enter="onClickQuery"
                  clearable
                  placeholder="Enter your order / tracking number"
                  class="input"
                ></el-input>
              </el-col>
              <el-col :lg="12" :md="12" :sm="12" :xs="24" class="input-col">
                <el-input
                  v-model="emailInput"
                  @input="onInput"
                  @keyup.enter="onClickQuery"
                  clearable
                  placeholder="Enter your email address"
                  class="input"
                ></el-input>
              </el-col>
            </el-row>
            <el-button
              :loading="comboData.loading"
              @click="onClickQuery"
              type="primary"
              class="query-btn"
              >Track</el-button
            >
          </div>
        </div>
      </el-affix>
      <div class="content">
        <div class="errors" v-if="show.error">
          <p
            class="error-msg"
            v-for="(item, index) of comboData.errorMsg"
            :key="index"
          >
            {{ item }}
          </p>
        </div>

        <template v-if="show.content">
          <ItemTopTable :data="itemTopTableData" v-if="show.itemTopTable" />
          <template v-for="(item, index) of comboData.list" :key="index">
            <ItemPackageTable
              :data="item.products"
              v-if="item.products.length"
              :index="index"
              :activeNames="item.activeNames"
            />
            <ItemLogisticsInfo
              :data="item.logisticeInfo"
              :activeNames="item.activeNames"
            />
          </template>
        </template>
      </div>
    </el-col>
  </el-row>
</template>

<script lang="ts">
import { defineComponent, provide, reactive, ref } from 'vue'
import { request } from './libs/request'
import ItemTopTable from '@/components/item-top-table.vue'
import ItemPackageTable from '@/components/item-package-table.vue'
import ItemLogisticsInfo from '@/components/item-logistics-info.vue'

const NODE = [
  { code: '200', zh: '物流商已收货', en: 'Received by carrier', icon: 1 },
  { code: '201', zh: '物流商已发货', en: '' },
  { code: '202', zh: '离开始发国', en: 'Departure', icon: 2 },
  { code: '203', zh: '到达目的国', en: 'Arrival', icon: 3 },
  { code: '204', zh: '末端提取', en: 'Last mile scan', icon: 4 },
  { code: '205', zh: '运输途中', en: '' },
  { code: '206', zh: '妥投', en: 'Delivery', icon: 5 },
  { code: '207', zh: '退件', en: '' },
]

type TDataNode = {
  trackingTime: string;
  trackingContent: string;
  ruleNodeCode: string;
};
type TViewNode = TDataNode & { icon?: number };

type TDataItem = {
  curIcon: number;
  currentRuleNodeCode?: string;
  currentRuleNodeEn?: string;
  endJourneyTrackingNumber: string;
  endJourneyShippingProviderName: string;
  endJourneyUrl: string;
  timelineList: TViewNode[];
  breadcrumbList: Array<{ name: string; completed: boolean }>;
  shippingProviderUrl: string;
  shippingProviderEnglishName: string;
  trackingNumber: string;
};
type TItemTopTableItem = {
  value: string;
  label: string;
  [key: string]: any;
};

type TComboItem = {
  products: Record<string, unknown>[];
  trackingRedisList: Record<string, unknown>[];
};

type TQuery = {
  order: string;
  email:string
};
export default defineComponent({
  name      : 'App',
  components: {
    ItemTopTable,
    ItemPackageTable,
    ItemLogisticsInfo,
  },
  setup() {
    function getQueryVal(): TQuery {
      const v = {
        order: '',
        email: '',
      }
      const _search = location.search
      if (_search) {
        const match = _search.match(/(?!\?)([^&=]+)=([^&=]*)/g)
        if (match) {
          match.reduce((ret, cur) => {
            const [k, v = ''] = cur.split('=')
            ret[k] = v
            return ret
          }, v)
        }
      }
      return v
    }
    const firstQueryVal = getQueryVal()
    let firstFlag = true

    const clientWidth = document.body.clientWidth
    const appProvide = reactive({
      xs: clientWidth <= 768,
    })
    provide('appProvide', appProvide)

    const itemTopTableData = ref<TItemTopTableItem[]>([
      {
        value  : '',
        key    : 'orderNumber',
        label  : 'Order Number',
        realKey: 'orderNo',
      },
      { value: '', key: 'recipient', label: 'Recipient' },
      { value: '', key: 'email', label: 'E-mail', realKey: 'buyerEmail' },
      { value: '', key: 'shippingAddress', label: 'Shipping Address' },
      { value: '', key: 'total', label: 'Total' },
    ])
    const itemPackageTableData = ref([])

    const input = ref(firstQueryVal.order)
    const tip = reactive({
      show      : false,
      content   : 'Please enter order / tracking number',
      contentMap: {
        order: 'Please enter order / tracking number',
        email: 'Please enter email address',
      },
    })
    let timer: ReturnType<typeof setTimeout> | null = null
    const show = reactive({
      error       : false,
      content     : false,
      itemTopTable: false,
    })

    const emailInput = ref(firstQueryVal.email)
    function onInput() {
      if (input.value.trim() && emailInput.value.trim()) {
        tip.show = false
        show.error = false
        if (timer) {
          clearTimeout(timer)
          timer = null
        }
      }
    }

    const comboData = reactive<{
      loading: boolean;
      list: TComboItem[];
      errorMsg: string[];
    }>({
      loading : false,
      list    : [],
      errorMsg: [],
    })

    const breadcrumbNode = NODE.filter((node) => node.icon).map((node) => ({
      icon: node.icon,
      en  : node.en,
      code: node.code,
    }))

    function getLogisticeInfo(itm: any) {
      itm.maxcurIcon = 0
      const timelineList = (itm.realNodeList || []).map((item: TViewNode) => {
        const { ruleNodeCode } = item
        if (ruleNodeCode) {
          item.icon = (
            NODE.find((node) => node.code === ruleNodeCode) as any
          ).icon
          const node = breadcrumbNode.find(
            (node) => node.code === ruleNodeCode
          )
          if (node) {
            itm.maxcurIcon =
              (node.icon as number) > itm.maxcurIcon
                ? node.icon
                : itm.maxcurIcon
          }
        }
        return item
      })

      const rest: Pick<
        TDataItem,
        'curIcon' | 'currentRuleNodeEn' | 'currentRuleNodeCode'
      > = { curIcon: 0 }
      const { currentRuleNodeCode } = itm
      if (currentRuleNodeCode) {
        let findIndex = NODE.findIndex(
          (node) => node.code === currentRuleNodeCode
        ) as number
        if (findIndex > -1) {
          const node = NODE[findIndex] as any
          rest.currentRuleNodeEn = node.en
          rest.curIcon = node.icon
          if (!rest.curIcon) {
            for (; ; findIndex--) {
              const curNodeIcon = NODE[findIndex].icon
              if (curNodeIcon) {
                rest.currentRuleNodeEn = NODE[findIndex].en
                rest.curIcon = curNodeIcon
                break
              }
            }
          }
        }
      }
      const breadcrumbList = breadcrumbNode.map((node) => {
        return {
          name     : node.en,
          completed: (node.icon as number) <= itm.maxcurIcon,
        }
      })
      return {
        endJourneyTrackingNumber      : itm.endJourneyTrackingNumber,
        endJourneyShippingProviderName: itm.endJourneyShippingProviderName,
        endJourneyUrl                 : itm.endJourneyUrl,
        timelineList,
        breadcrumbList,
        shippingProviderUrl           : itm.shippingProviderUrl,
        shippingProviderEnglishName   : itm.shippingProviderEnglishName,
        trackingNumber                : itm.trackingNumber,
        ...rest,
      }
    }
    function fetchAllData(keyword: string, emailKeyword: string) {
      if (comboData.loading) return
      comboData.loading = true

      request({
        url   : '/api/order/getOrderInfo',
        params: {
          keyword,
          email: emailKeyword,
        },
      }).then((res:any) => {
        comboData.loading = false
        const { success, data, error } = res as Global.ApiRet
        const hasData = data && !!Object.keys(data).length
        Object.assign(show, {
          error  : !success,
          content: success && hasData,
        })
        if (success) {
          if (!hasData) {
            show.error = true
            comboData.errorMsg = ['Data Not Found!']
            return
          }
          show.itemTopTable = !!data.receivingAddress
          itemTopTableData.value = itemTopTableData.value.map((item) => {
            const { key, realKey, label } = item
            const _item = {
              label,
              value: '',
              key,
              realKey,
            }
            const receivingAddress =
            (data!.receivingAddress as Record<string, unknown>) || {}
            if (key === 'shippingAddress') {
              _item.value = [
                receivingAddress.country,
                receivingAddress.province,
                receivingAddress.city,
                receivingAddress.zip,
                receivingAddress.address1,
                receivingAddress.address2,
              ]
                .filter(Boolean)
                .join()
            } else if (key === 'total') {
              _item.value = `${data.currencyCode} ${data.total}`
            } else if (key === 'recipient') {
              _item.value = receivingAddress.contactName as string
            } else {
              _item.value = (data[realKey || key] as string) || ''
            }
            return _item
          })
          if (data.packages) {
            const open = data.packages.length === 1
            comboData.list = data.packages.map((item: TComboItem) => {
              return {
                activeNames  : open ? ['1'] : [],
                products     : item.products || [],
                logisticeInfo: getLogisticeInfo(
                  item.trackingRedisList ? item.trackingRedisList[0] : {}
                ),
              }
            })
          }
        } else {
          comboData.errorMsg = error ? error.message.split(/\r\n/g) : []
        }

      }).catch(() => {
        comboData.loading = false
      })
    }

    function setTip(inputValIsNull: boolean) {
      tip.content = tip.contentMap[inputValIsNull ? 'order' : 'email']
      tip.show = true
      if (timer) {
        clearTimeout(timer)
      }
      timer = setTimeout(() => {
        tip.show = false
        timer = null
      }, 3000)
      Object.assign(show, {
        error  : false,
        content: false,
      })
    }
    function onClickQuery() {
      const val = input.value.trim()
      const emailVal = emailInput.value.trim()
      if (!val || !emailVal) {
        setTip(!val)
        if (firstFlag) {
          firstFlag = false
          return
        }
      } else {
        const initialQueryVal = getQueryVal()
        if (firstFlag && initialQueryVal.order) {
          firstFlag = false
          fetchAllData(initialQueryVal.order, emailVal)
        } else {
          firstFlag = false
          window.history.pushState(
            {
              lastQueryOrderVal: val,
              lastQueryEmailVal: emailVal,
            },
            '',
            `?order=${val}`
          )
          fetchAllData(val, emailVal)
        }
      }
    }
    window.addEventListener('popstate', (e) => {
      const { state } = e
      input.value =
        state && state.lastQueryOrderVal
          ? state.lastQueryOrderVal
          : firstQueryVal.order
      emailInput.value =
        state && state.lastQueryEmailVal ? state.lastQueryEmailVal : ''
      if (!input.value || !emailInput.value) {
        setTip(!input.value)
      } else {
        fetchAllData(input.value, emailInput.value.trim())
      }
    })
    onClickQuery()

    return {
      input,
      onInput,
      tip,
      onClickQuery,
      comboData,
      show,
      itemTopTableData,
      itemPackageTableData,
      emailInput,
    }
  },
})
</script>

<style lang="scss">
html {
  overflow-y: scroll;
}

:root {
  overflow-x: hidden;
  overflow-y: auto;
}

:root body {
  position: absolute;
}

body {
  width: 100vw;
  margin: 0;
  overflow: hidden;
}

.affix {
  background: #fff;

  .title {
    padding-top: 5vh;
    padding-bottom: 5vh;
    margin: 0;
    font-size: 48px;
    line-height: 1.4;
    color: $--color-primary;
    text-align: center;
  }

  .breadcrumbs {
    padding: 40px 0 20px;
  }
}

$fontSize: 18px;

.query-wrapper {
  position: relative;
  display: flex;

  .tip {
    position: absolute;
    bottom: 100%;
    left: 0;
    padding: 5px 12px;
    margin: 0 0 10px 0;
    font-size: 14px;
    line-height: 1.5;
    color: #fff;
    background: $--color-primary;
    border-radius: 5px;
  }

  .input {
    .el-input__inner {
      height: 60px;
      font-size: $fontSize;
      &::placeholder {
        font-size: 14px;
      }
    }
  }

  .el-input .el-input__clear {
    font-size: 20px;
  }

  .query-btn {
    width: 15%;
    min-width: 80px;
    padding-right: 0;
    padding-left: 0;
    margin-left: 5px;
    font-size: $fontSize;
  }
}

.breadcrumbs {
  display: flex;
  padding: 10px 0 50px;
  margin: 0;
  list-style: none;
}

.breadcrumb {
  display: flex;
  flex: 1;
  justify-content: space-between;

  &.active {
    .dots {
      .dot {
        background: $--color-primary;
      }
    }
  }
  $imgSize: 40px;

  .detail {
    flex: 1;
    text-align: center;

    img {
      width: $imgSize;
      height: $imgSize;
    }

    .name {
      margin: 12px 0 0;
      font-size: 16px;
      line-height: 1.375;
      color: $--color-text-primary;
    }
  }

  .dots {
    display: flex;
    align-items: center;
    height: $imgSize;

    .dot {
      width: 7px;
      height: 7px;
      background: #c5c5c5;
      border-radius: 50%;

      + .dot {
        margin-left: 9px;
      }
    }
  }
}

// md - sm
@media only screen and (max-width: $screen-sm) {
  .query-wrapper {
    .input {
      .el-input__inner {
        height: 40px;
        font-size: $fontSize;
        &::placeholder {
          font-size: 12px;
        }
      }
    }
  }
}

@media only screen and (max-width: $screen-xs) {
  .affix {
    .title {
      padding-top: 20px;
      padding-bottom: 32px;
      font-size: 32px;
    }
  }
  $fontSize: 14px;

  .query-wrapper {
    .input-col {
      + .input-col {
        margin-top: 5px;
      }

      .el-input__inner {
        padding-left: 8px;
      }
    }

    .input {
      .el-input__inner {
        height: 40px;
        font-size: $fontSize;
        &::placeholder {
          font-size: 10px;
        }
      }
    }

    .el-input .el-input__clear,
    .query-btn {
      font-size: 18px;
    }

    .tip {
      padding: 2px 4px;
      margin-bottom: 2px;
      font-size: 12px;
      border-radius: 4px;
    }
  }

  .breadcrumbs {
    padding-top: 24px;
    padding-bottom: 30px;
  }

  .breadcrumb {
    $imgSize: 24px;

    .detail {
      img {
        width: $imgSize;
        height: $imgSize;
      }

      .name {
        margin: 6px 0 0;
        font-size: 12px;
      }
    }

    .dots {
      position: absolute;
      height: $imgSize;
      line-height: $imgSize;
      transform: translateX(-50%);

      .dot {
        width: 4px;
        height: 4px;

        + .dot {
          margin-left: 5px;
        }
      }
    }
  }

  .content {
    margin-top: 10px;

    .errors {
      margin-top: 0;
    }

    .error-msg,
    .error-help {
      font-size: $fontSize;
    }

    .logistics-info {
      // p {
      //   font-size: 14px;
      // }

      .desc {
        display: block;

        p {
          margin-top: 10px;
        }
      }
    }

    .timelines {
      padding: 12px 6px;
      border-radius: 4px;
      $iconSize: 16px;

      .timeline {
        &:not(:last-child) {
          .left {
            padding-bottom: 36px;

            &::before {
              right: 7px;
              bottom: -$iconSize / 2;
            }
          }
        }
      }

      .time {
        width: 142px;
        font-size: 13px;
      }

      .icon {
        display: flex;
        align-items: center;
        justify-content: center;
        width: $iconSize;
        height: $iconSize;
        margin-left: 0;

        .dot {
          width: 9px;
          height: 9px;
        }
      }

      .msg {
        margin: 0 0 0 12px;
        font-size: 14px;
      }
    }

    .track {
      margin-bottom: 20px;
    }
  }
}
</style>
