<template>
	<el-collapse class="logistics-info-collapse" :model-value="activeNames">
		<el-collapse-item title="Logistics information" name="1">
      <template v-if="showInfo || showTimelines">

        <div 
          class="logistics-info" 
          v-if="showInfo">
          <p class="state" v-if="data.currentRuleNodeEn">Current state：{{data.currentRuleNodeEn}}</p>
          <div class="desc" v-if="data.trackingNumber">
            <p v-if="data.trackingNumber">Original tracking number:  <span>{{data.trackingNumber}}</span></p>
          </div>
          <div class="desc" v-if="data.endJourneyTrackingNumber || data.endJourneyShippingProviderName || data.shippingProviderUrl || data.shippingProviderEnglishName">
            <p v-if="data.endJourneyTrackingNumber">Delivery tracking number:  <span>{{data.endJourneyTrackingNumber}}</span></p>
            <p v-if="data.endJourneyShippingProviderName">Delivery channel: <span class="url" v-if="!data.endJourneyUrl">{{data.endJourneyShippingProviderName}}</span><a v-else target="_blank" :href="data.endJourneyUrl">{{data.endJourneyShippingProviderName}}</a></p>
					  <p v-if="data.shippingProviderUrl || data.shippingProviderEnglishName">Logistics provider: <span class="url" v-if="!data.shippingProviderUrl">{{data.shippingProviderEnglishName}}</span><a v-else target="_blank" :href="data.shippingProviderUrl">{{data.shippingProviderEnglishName}}</a></p>
          </div>
        </div>
        <ul class="timelines" v-if="showTimelines">
          <li
            v-for="(itm,index) of data.timelineList"
            :key="index"
            class="timeline">
            <div class="left">
              <span class="time">{{itm.trackingTime}}</span>
            </div>
            <p class="msg">{{itm.trackingContent}}</p>
          </li>
        </ul>
      </template>
      <p v-else style="padding: 0 20px; font-size: 15px;">No information</p>
		</el-collapse-item>
	</el-collapse>
</template>
<script lang="ts">
import { computed, defineComponent } from 'vue'

export default defineComponent({
  name : 'ItemLogisticsInfo',
  props: {
    data       : Object,
    activeNames: Array,
  },
  setup(props) {
    const showInfo = computed(() => {
      const { data } = props
      if (!data) {
        return false
      }
      return data.currentRuleNodeEn || data.endJourneyTrackingNumber || data.endJourneyShippingProviderName || data.trackingNumber || data.shippingProviderUrl || data.shippingProviderEnglishName
    })

    const showTimelines = computed(() => {
      const { data } = props
      if (!data) {
        return false
      }
      return data.timelineList && data.timelineList.length
    })
    return {
      showInfo,
      showTimelines,
    }
  },
})
</script>
<style lang="scss">
.logistics-info {
  padding: 10px;

  p {
    margin: 0;
    font-size: 16px;
    line-height: 40px;
    color: $--color-text-primary;
  }

  .desc {
    display: flex;
    justify-content: space-between;

    p {
      flex: 1;
      line-height: 40px;
    }
  }

  span {
    color: $--color-primary;
  }

  a {
    color: $--color-primary;
    text-decoration: underline;
    cursor: pointer;
  }

  .url {
    color: $--color-primary;
  }
}

.timelines {
  padding: 30px 20px 20px;
  margin: 0;
  color: $--color-text-primary;
  list-style: none;
  background: rgba($color: #f5f5f5, $alpha: 0.3);
  border-radius: 10px;
  $iconSize: 24px;

  .timeline {
    display: flex;

    &:not(:last-child) {
      .left {
        position: relative;
        z-index: 1;
        padding-bottom: 40px;

        // &::before {
        //   position: absolute;
        //   top: 12px;
        //   right: 11px;
        //   bottom: - $iconSize / 2;
        //   z-index: -1;
        //   width: 1px;
        //   content: '';
        //   background: #e6e6e6;
        // }
      }
    }
  }

  .left {
    display: flex;
    align-items: flex-start;
    padding: 0;
    margin: 0;
  }

  .time {
    width: 170px;
    font-size: 16px;
    line-height: 1.375;
  }

  .icon {
    z-index: 1;
    width: 23px;
    height: 23px;
    margin-left: 40px;
    text-align: center;

    .dot {
      display: inline-block;
      width: 13px;
      height: 13px;
      background: #d8d8d8;
      border-radius: 50%;
    }
  }

  .msg {
    flex: 1;
    margin: 0 0 0 30px;
    font-size: 16px;
    line-height: 1.375;
    color: $--color-text-primary;
  }
}
</style>