<template>
	<ul class="item-top-table">
		<li 
			v-for="(item,index) of data"
			:key="index"
			class="cell">
			<span class="label">{{item.label}}：</span>
			<span class="value">{{item.value}}</span>
		</li>
	</ul>
</template>
<script lang="ts">
import { defineComponent } from 'vue'
export default defineComponent({
  name : 'ItemTopTable',
  props: {
    data: Array,
  },
})
</script>
<style lang="scss" scoped>
.item-top-table {
  display: grid;
  grid-template-columns: 4.5fr 3fr 3.5fr;
  padding: 0;
  list-style: none;
  border-bottom: 1px solid $border-color-light;
  border-left: 1px solid $border-color-light;

  .cell {
    display: flex;
    padding: 10px;
    border-top: 1px solid $border-color-light;
    border-right: 1px solid $border-color-light;

    &:nth-child(4) {
      grid-column-start: span 2;
    }

    .label {
      flex-shrink: 0;
      color: #606266;
    }

    .value {
      word-break: break-all;
    }
  }
}

@media only screen and (max-width: $screen-xs) {
  .item-top-table {
    display: block;
  }
}
</style>