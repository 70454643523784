
import { defineComponent, inject } from 'vue'

export default defineComponent({
  name : 'ItemPackageTable',
  props: {
    data       : Array,
    index      : Number,
    activeNames: Array,
  },
  setup() {
    const appProvide = inject('appProvide')
    return {
      appProvide,
    }
  },
})
