import axios, {
  AxiosInstance,
  AxiosRequestConfig,
  AxiosResponse,
  AxiosError
} from 'axios'

const baseURL = process.env.NODE_ENV !== 'development' 
  ? `${process.env.baseURL || ''}` 
  : ''
const axiosOptions:AxiosRequestConfig = {
  baseURL,
}
const instance:AxiosInstance = axios.create(axiosOptions)

instance.interceptors.request.use((config:AxiosRequestConfig) => {
  return config
}, (error:AxiosError) => {
  return Promise.reject(error)
})

instance.interceptors.response.use((response:AxiosResponse) => {
  return response.data
}, (error:AxiosError) => {
  return Promise.reject(error)
})
export const request = instance
export default axios
