
import { computed, defineComponent } from 'vue'

export default defineComponent({
  name : 'ItemLogisticsInfo',
  props: {
    data       : Object,
    activeNames: Array,
  },
  setup(props) {
    const showInfo = computed(() => {
      const { data } = props
      if (!data) {
        return false
      }
      return data.currentRuleNodeEn || data.endJourneyTrackingNumber || data.endJourneyShippingProviderName || data.trackingNumber || data.shippingProviderUrl || data.shippingProviderEnglishName
    })

    const showTimelines = computed(() => {
      const { data } = props
      if (!data) {
        return false
      }
      return data.timelineList && data.timelineList.length
    })
    return {
      showInfo,
      showTimelines,
    }
  },
})
